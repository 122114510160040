import axios from 'axios';
import {
    BASE_URL, SERVICES, LIST_TO_VALUES,
    LIST_SERVICES_DECRYPT,
    PATHS, LIST_SERVICES_NOS
} from '../properties';
import moment from 'moment';
import firebase from 'firebase';
import jQuery from 'jquery';
import Crypto from 'crypto';
import M from 'materialize-css';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email);
}

export function getMonthString(m) {
    let d = moment().month();
    let month = "";

    if (m >= 0) {
        d = m;
    }
    switch (d) {
        case 0:
            month = 'Enero';
            break;
        case 1:
            month = 'Febrero';
            break;
        case 2:
            month = 'Marzo';
            break;
        case 3:
            month = 'Abril';
            break;
        case 4:
            month = 'Mayo';
            break;
        case 5:
            month = 'Junio';
            break;
        case 6:
            month = 'Julio';
            break;
        case 7:
            month = 'Agosto';
            break;
        case 8:
            month = 'Septiembre';
            break;
        case 9:
            month = 'Octubre';
            break;
        case 10:
            month = 'Noviembre';
            break;
        case 11:
            month = 'Diciembre';
            break;
        default:
            month = "";
            break;
    }
    return month;
}

export function getMonthStringGraphs(m) {
    let d = moment().month();
    let month = "";

    if (m >= 0) {
        d = m;
    }

    switch (d) {
        case 1:
            month = 'Enero';
            break;
        case 2:
            month = 'Febrero';
            break;
        case 3:
            month = 'Marzo';
            break;
        case 4:
            month = 'Abril';
            break;
        case 5:
            month = 'Mayo';
            break;
        case 6:
            month = 'Junio';
            break;
        case 7:
            month = 'Julio';
            break;
        case 8:
            month = 'Agosto';
            break;
        case 9:
            month = 'Septiembre';
            break;
        case 10:
            month = 'Octubre';
            break;
        case 11:
            month = 'Noviembre';
            break;
        case 12:
            month = 'Diciembre';
            break;
        default:
            month = "";
            break;
    }
    return month;
}



export function esBienvenida() {
    let esBienvenida = localStorage.getItem('bienvenida');

    if (!esBienvenida) {
        localStorage.setItem('bienvenida', "true");
        return true;
    }
    return false;
}

export function onlyDigits(value) {
    var re = /^[0-9]*$/;
    return re.test(value);
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toCamelCase(str) {
    return str.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).toUpperCase();
}

export function nWithCommas(x) {
    //return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export async function makeCall(service, data, callbackError) {
    
    let token = false;
    let refreshToken = false;
    let authorization = false;

    // debugger;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }


    if (localStorage.getItem('authorization')) {
        authorization = localStorage.getItem('authorization');
    }

    if (token !== authorization) {
        authorization = token;
    }

    if (token && LIST_SERVICES_NOS.indexOf(service.url) > -1) {
        authorization = token;
    }

    if (localStorage.getItem('idToken')) {
        
        authorization = localStorage.getItem('idToken');
      
    }


    if (localStorage.getItem('refreshToken')) {
        refreshToken = localStorage.getItem('refreshToken');
    }


    if (!data) {
        data = {}
    }


    try {
        let url = service.url;
        let baseURL = BASE_URL;

        if (service.urlOriginal) {
            url = service.urlOriginal;
        }


        if (service.esINFOCEL) {
            baseURL = "";
        } else {

            service.body.AppID = 'com.valmex.valmexcb';
            service.body.SistemaOperativo = navigator.platform;
        }

        if (service.method.toUpperCase() === 'GET') {
            let keys = Object.keys(service.body);
            for (let i = 0; i < keys.length; i++) {
                let op = "&";
                if (i === 0) {
                    op = "?"
                }
                url += `${op}${keys[i]}=${data[keys[i]]}`;
            }
        }

        //Config request se genera de acuedo a la documentación Axios.  https://github.com/axios/axios#axiosrequestconfig
        let configRequest = {
            url,
            method: service.method,
            baseURL,
            data
        }


        if (service.esINFOCEL) {
            configRequest.baseURL = "";
            configRequest.headers = {
                "Access-Control-Allow-Origin": "*"
            }

        }

        if (authorization) {

            configRequest.headers = {
                "authorization": authorization
            }
        }


        if (data.hasOwnProperty('RefreshToken') && refreshToken && refreshToken.trim() !== '') {
            data.RefreshToken = encryptValues(refreshToken);

            // if (configRequest.headers) {
            //     configRequest.headers['RefreshToken'] = refreshToken;
            // } else {
            //     configRequest.headers = {
            //         "RefreshToken": refreshToken
            //     }
            // }
        }

        if (data.hasOwnProperty('Token') && token && token.trim() !== '') {
            data.Token = encryptValues(token);
        }

        if (service.esINFOCEL) {
            let re = await jQuery.get(url);
            return re;
        } else {
            if (data["UsuarioServicio"] != null) {
                //data["UsuarioServicio"] = encryptValues(window.config.USUARIO_SERVICIO);
                //data["ContraseniaServicio"] = encryptValues(window.config.CONTRASENA_SERVICIO);
            }

            data["app"] = "web";
            // console.log("REQUEST");
            // console.log(configRequest);
            
            let result = await axios.request(configRequest);
            let toReturn = readObject(result.data, service.url);
            //  debugger;
            // console.log("RESPONSE");
            // console.log(toReturn);
            if (toReturn && toReturn.response && toReturn.response.MensajeError === "Cliente sin acceso"
                && service.url !== "seguridad/cerrarSesion"
            ) {

                localStorage.removeItem('dataUser');
                window.location.href = `${PATHS.SIN_AUTORIZACION}`;

            } else {

                if (toReturn.response && toReturn.response.TokenSesion &&
                    toReturn.response.TokenSesion.trim() !== "") {
                       
                        
                    localStorage.setItem('token', decryptValue(toReturn.response.TokenSesion));
                }

                if (toReturn.response && toReturn.response.RefreshTokenSesion &&
                    toReturn.response.RefreshTokenSesion.trim() !== "") {
                      
                    localStorage.setItem('refreshToken', decryptValue(toReturn.response.RefreshTokenSesion));
                }

                if (toReturn.response && toReturn.response.CurrentToken &&
                    toReturn.response.CurrentToken.trim() !== "") {
                    try {
                       
                        localStorage.setItem('token', decryptValue(toReturn.response.CurrentToken));
                    } catch (error) {
                    }

                }


                if (toReturn.response && toReturn.response.firebase &&
                    toReturn.response.firebase.idToken) {

                    try {
                   
                        localStorage.setItem('idToken', decryptValue(toReturn.response.firebase.idToken));
                    } catch (error) {
                    }
                }


                return toReturn;
            }



        }



    } catch (error) {
       
        if (error && error.response
        ) {

            if (error.response.hasOwnProperty('data') &&
                (error.response.data.msg === "Sin autorización"
                    || error.response.data.msg === "La petición no tiene la cabezera de autenticación")
            ) {
                try {
                    firebase.auth().signOut();
                } catch (error) {

                } finally {

                    let instance = M.Sidenav.getInstance(document.querySelectorAll('.sidenavUser')[0]);
                    if (instance) {
                        instance.close();
                    }

                    if (localStorage.getItem('dataUser')) {
                        let dataU = JSON.parse(localStorage.getItem('dataUser'))
                        deleteSesion(dataU.IdUsuario);
                    }

                    if (callbackError) {
                        callbackError(error);
                    } else {
                        return false;
                    }

                    window.location.href = `${PATHS.SIN_AUTORIZACION}`;


                }
            }

        } else {

            if (callbackError) {
                callbackError(error);
            } else {
                throw error;
            }

        }

    }




}


export const REGEXS_NEW_PASS = [
    {
        text: "Debe tener entre 10 y 12 caracteres.",
        validator: (value) => {
            if (value.length >= 10 && value.length <= 12) {
                return true;
            }

            return false;
        },
        isCorrect: false
    },
    {
        text: "Debe contener minimo 1 mayúscula, 1 minúscula, 1 número y 1 caracter especial (|!#$%&/()=?¡¿+*{[}]-_.:,;)",
        validator: (value) => {
            let re =  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[|!#$%&/()=?¡¿+*{[}\]-_.:,;])[A-Za-z\d|!#$%&/()=?¡¿+*{[}\]-_.:,;]{10,12}$/;
            ///^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])(?=\w*[|!#$%&/()=?¡¿+*{[}\]-_.:,;])\S{10,12}$/;
            return re.test(value);
        },
        isCorrect: false
    },
    {
        text: "No debe incluir el identificador del usuario",
        validator: (value) => {
            return true;
        },
        isCorrect: false
    },
    {
        text: "No debe incluir el nombre de la casa de bolsa (VALMEX, VALORES, MEXICANOS)",
        validator: (value) => {
            let names = ['VALMEX', 'VALORES', 'MEXICANOS'];
            let include = true;
            names.forEach((v, i) => {
                if (value.toUpperCase().includes(v)) {
                    include = false;
                }
            });

            return include;
        },
        isCorrect: false
    },
    {
        text: "No debe contener más de dos caracteres consecutivos iguales (p.e. 222, eee)",
        validator: (value) => {
            let re = /^(?=.{10,12}$)(([a-z0-9])\2?(?!\2))+$/;
            return !re.test(value);
        },
        isCorrect: false
    },
    {
        text: "No debe contener más de dos caracteres consecutivos (p.e. 123, abc)",
        validator: (value) => {
            return true;
        },
        isCorrect: false
    },
    {
        text: "No debe contener espacios en blanco.",
        validator: (value) => {
            let re = /\s/;
            return !re.test(value);
        },
        isCorrect: false
    }
]

export function getPosicionCartera(cartera) {
    let ordenRama = {};
    let ordenFinal = {
        totalCartera: false,
        detalle: []
    };
    for (let ca of cartera) {
        //Ordenar por rama.
        let character = ca.Rama.trim().charAt(0);

        if (ordenRama[character]) {
            ordenRama[character].info.push(ca);
        } else {
            ordenRama[character] = {
                info: [ca]
            }
        }
    }

    //Ordena por niveles despues de ordenarRama
    let keys = Object.keys(ordenRama);

    for (let ks of keys) {
        let data = ordenRama[ks].info;

        for (let dt of data) {
            if (ordenRama[ks][dt.Nivel]) {
                ordenRama[ks][dt.Nivel].push(dt);
            } else {
                ordenRama[ks][dt.Nivel] = [dt];
            }
        }

        if (ordenRama[ks]['4'] && ordenRama[ks]['3']) {
            //Ordena el detalle del nivel 4 en el nivel 3
            let dataInfoLevelTres = ordenRama[ks]['3'];
            let dataInfoLevelCuatro = ordenRama[ks]['4'];

            for (let dit of dataInfoLevelTres) {
                //console.log(dit);
                let filter = dataInfoLevelCuatro.filter((v, i) => {
                    if (v.Rama === dit.Rama) {
                        return v;
                    }
                });
                dit.detalle = filter;
            }
            delete ordenRama[ks]['4'];

        }

        if (ordenRama[ks]['2'] && ordenRama[ks]['3']) {
            let dataInfoLvl2 = ordenRama[ks]['2'];
            let dataInfoLvl3 = ordenRama[ks]['3'];

            for (let dil2 of dataInfoLvl2) {
                let rama = dil2.Rama;
                dil2.detalle = [];
                for (let dil3 of dataInfoLvl3) {
                    if (dil3.Rama.includes(rama)) {
                        dil2.detalle.push(dil3);
                    }
                }
            }

            delete ordenRama[ks]['3']
        }

        if (ordenRama[ks]['1'] && ordenRama[ks]['2']) {
            let dataInfoLvl1 = ordenRama[ks]['1'];
            let dataInfoLvle2 = ordenRama[ks]['2'];

            for (let dil1 of dataInfoLvl1) {
                let rama = dil1.Rama;
                dil1.detalle = [];
                for (let dile2 of dataInfoLvle2) {
                    if (dile2.Rama.charAt(0).trim() === rama.trim()) {
                        dil1.detalle.push(dile2);
                    }
                }
            }

            delete ordenRama[ks]['2']
        }

        if (ordenRama[ks]['0']) {
            ordenFinal['totalCartera'] = ordenRama[ks]['0'];
        }

        if (ordenRama[ks]['1']) {
            ordenFinal['detalle'].push(ordenRama[ks]['1']);
        }

        delete ordenRama[ks]['info'];
    }

    //return ordenRama;
    return {
        ordenRama: ordenFinal,
        total: ordenFinal.totalCartera && ordenFinal.totalCartera.length ? `$${nWithCommas(parseFloat(ordenFinal.totalCartera[0].MontoMdoMN).toFixed(2).toString())}` : "",
        data: {
            labels: ['Saldo Líquido',
                'Instrumentos de deuda y fondos de inversión de deuda',
                'Acciones y fondos de inversión de renta variable',
                'Instrumentos en otras divisas y fondos de inversión de deuda en otras divisas',
                'Derivados', 'Reportos Vigentes'],
            datasets: [
                {
                    data: [
                        30,
                        25,
                        15,
                        10,
                        10,
                        10
                    ],
                    backgroundColor: [
                        '#12C3E2',
                        '#6CDBC7',
                        '#A6D043',
                        '#A25EB5',
                        '#FFA400',
                        '#B7DDE1',
                    ]
                }
            ]
        }
    }
}

export async function getDivisas() {

    let objToReturn = []

    try {

        let resultDivisas = await makeCall(SERVICES.INFOCEL_DIVISAS, {});

        if (resultDivisas && resultDivisas.divisas) {
            objToReturn.push({
                name: "Divisas",
                d: resultDivisas.divisas.rows
            })
        }
    } catch (error) {

    }

    try {
        let resultMercancias = await makeCall(SERVICES.INFOCEL_MERCANCIAS, {});

        if (resultMercancias && resultMercancias.divisas) {
            objToReturn.push({
                name: "Mercancías",
                d: resultMercancias.divisas.rows
            })
        }
    } catch (error) {

    }


    try {

        let resultDeuda = await makeCall(SERVICES.INFOCEL_DEUDA, {});

        if (resultDeuda && resultDeuda.divisas) {
            objToReturn.push({
                name: "Deuda",
                d: resultDeuda.divisas.rows
            })
        }

    } catch (error) {

    }

    return objToReturn;
}

export async function getSaldoLiquido(props) {
    try {

        let bodySaldoLiquido = SERVICES.SALDO_LIQUIDO.body;
        bodySaldoLiquido.IdUsuario = encryptValues(props.dataUser ? props.dataUser.IdUsuario : "");
        bodySaldoLiquido.Contrato = encryptValues(props.currentContrato ? props.currentContrato.Contrato : "");
        bodySaldoLiquido.TipoSaldo = props.TipoSaldo;

        let resS = await makeCall(SERVICES.SALDO_LIQUIDO, bodySaldoLiquido);
        //
        if (resS && resS.response && resS.response.Exito === 200) {
            let saldos = resS.response['TTSALDOLIQUIDO']['TT-SALDOLIQUIDO'];


            let rs = {
                saldoLiquido: saldos
            }

            rs.totalSaldo = 0;
            rs.totalSaldoMXP = 0;
            for (let s of saldos) {
                if (s.Plazo === 0 && s.Divisa === "MXP") {
                    rs.totalSaldoMXP += s.ImporteMXP;
                }

                //  if (s.Plazo === 0 && s.Divisa === "MXP") {
                if (s.Plazo === 0) {
                    rs.totalSaldo += s.ImporteMXP;
                }
            }

            return rs;
        }

        return 0;
    } catch (error) {

    }
}

export async function getIndices() {
    let objToReturn = []


    try {

        let resultIndices = await makeCall(SERVICES.INFOCEL_INDICES, {});

        if (resultIndices && resultIndices.divisas) {
            objToReturn = resultIndices.divisas.rows;
        }

    } catch (error) {
    }

    return objToReturn;

}

export async function getAcciones() {
    let objToReturn = [];

    try {

        let resultAcciones = await makeCall(SERVICES.INFOCEL_ACCIONES, {});

        if (resultAcciones && resultAcciones.divisas) {
            objToReturn = resultAcciones.divisas.rows;
        }
    } catch (error) {

    }

    return objToReturn;
}

export async function getCoordenadas(props) {
    if (navigator.geolocation) {
        return await new Promise(async (resolve) => {
            navigator.geolocation.getCurrentPosition(async (showPosition) => {
                let { latitude, longitude } = showPosition.coords;
           
                try {
                  
                    let rs = await getDireccion(latitude, longitude);

                    props.setCoordenadas({ latitude, longitude, pais: rs.pais, ciudad: rs.ciudad, estado:rs.estado, direccion: rs.address })
                    
                    resolve(true);

                } catch (error) {

                    props.okButton({
                        message: "No se logró obtener tu ubicación, lo cual es necesario por razones de seguridad y regulación para el uso de plataformas digitales. Revisa la configuración de tu navegador e intenta de nuevo.", title: "Advertencia", txtButton: "Entendido",
                        statusCode: 404
                    });

                    props.setCoordenadas(false);
                    resolve(false);
                }
            }, (error) => {
                props.okButton({
                    message: "Tu navegador no admite geolocalización, lo cual es necesario por razones de seguridad y regulación para el uso de plataformas digitales. Ajusta la configuración e intenta de nuevo.", title: "Advertencia", txtButton: "Entendido",
                    statusCode: 404
                });
                props.setCoordenadas(false);
                resolve(false);
            });
        })
    } else {
        props.okButton({
            message: "Tu navegador no admite geolocalización, lo cual es necesario por razones de seguridad y regulación para el uso de plataformas digitales. Ajusta la configuración e intenta de nuevo.", title: "Advertencia", txtButton: "Entendido",
            statusCode: 404
        });
        props.setCoordenadas(false);
        return false;
    }
}

export async function getDireccion(lat, long) {

    try {
        let url = `${BASE_URL}/apigoogle/obtenerDireccion`;

        let res = await axios.post(url, { lat, long });
        if (res && res.data && res.data.length > 0) {


            let pais,ciudad,estado = "";

            if(res.data[0].address_components && res.data[0].address_components.length){
                //Busca elementos para pais, ciiudad, estado
               
                for(let component of res.data[0].address_components){
                    if(component.types && component.types.length){
                        for(let type of component.types){
                            if(type === 'administrative_area_level_1'){
                                estado = component.long_name;
                            }

                            if(type === 'country'){
                                pais = component.long_name;
                            }

                            if(type === 'locality'){
                                ciudad = component.long_name;
                            }
                        }
                    }
                }
            }

            let address = res.data[0].formatted_address;

            return {
                address,
                ciudad,
                estado,
                pais
            };
        } else {
            return "";
        }
    } catch (error) {

        return "";
    }
}

export function setBitacora(data, email) {
    try {
        data.date = new Date();
        data.descripcion = 'Inicio de sesión web';
        data.plataforma = navigator.platform;
        firebase.firestore().collection(`/usuarios/${email}/bitacora`).add(data);
    } catch (error) {

    }
}

export function encryptValues(value) {
    try {
        const ivLength = 16;
        let iv = window.config.APP_RIV;
    
        // Verificar si el IV tiene la longitud correcta
        if (iv.length !== ivLength) {
            iv = Crypto.randomBytes(ivLength);
        }
    
        let cipher = Crypto.createCipheriv('aes-256-cbc', window.config.APP_KCR_OBS, iv);
        let encrypt = cipher.update(value + '', 'utf-8', 'base64');
        encrypt += cipher.final('base64');
        return encrypt;
    } catch (error) {
        return value;
    }
    // try {
    //     debugger;
    //     let cipher = Crypto.createCipheriv('aes-256-cbc', window.config.APP_KCR_OBS, window.config.APP_RIV);
    //     let encrypt = cipher.update(value + '', 'utf-8', 'base64');
    //     encrypt += cipher.final('base64');
    //     return encrypt;
    // } catch (error) {
    //     console.log(error);
    //     return value;
    // }

}

export function decryptValue(value) {
    try {
        
        const ivLength = 16;
        let iv = window.config.APP_RIV;
    
        // Verificar si el IV tiene la longitud correcta
        if (iv.length !== ivLength) {
            iv = Crypto.randomBytes(ivLength);
        }

        let decipher = Crypto.createDecipheriv('aes-256-cbc', window.config.APP_KCR_OBS, iv);
        var decrypted = decipher.update(value, 'base64', 'utf8');
        decrypted += decipher.final('utf8');
        return decrypted;
    } catch (error) {
        return value;
    }

}

export function decryptValueNot(value) {
    try {
        
        const ivLength = 16;
        let iv = window.config.APP_RIV;
    
        // Verificar si el IV tiene la longitud correcta
        if (iv.length !== ivLength) {
            iv = Crypto.randomBytes(ivLength);
        }

        let decipher = Crypto.createDecipheriv('aes-256-cbc', window.config.APP_KCR_OBS, iv);
        var decrypted = decipher.update(value, 'base64', 'utf8');
        decrypted += decipher.final('utf8');
        return decrypted;
    } catch (error) {
        return "";
    }

}


export function readObject(data, url) {

    try {
        if (LIST_SERVICES_DECRYPT.indexOf(url) > -1) {

            let keysRaiz = Object.keys(data);
            for (let keyR of keysRaiz) {

                if (LIST_TO_VALUES.indexOf(keyR) > -1 && data[keyR]) {

                    data[keyR] = decryptValue(data[keyR]);

                }

                if (data[keyR] !== null && data[keyR] !== false) {
                    if (Array.isArray(data[keyR])) {
                        for (let dataArr of data[keyR]) {
                            if (typeof dataArr === 'object') {
                                data[keyR] = readObject(data[keyR], url);
                            }
                        }
                    } else if (typeof data[keyR] === 'object') {
                        data[keyR] = readObject(data[keyR], url);
                    }
                }
            }
        }

        return data;
    } catch (error) {

        return data;
    }

}

export async function getData_menuExterior(){
    try {
        let snap = await firebase.firestore().doc(`/configuraciones/pantallas`).get();

        if(snap.exists){
       
            return snap.data();
        }
        return false;


    } catch (error) {
        return false;
    }
}

export async function getConfiguracionCR(){
    try {
        let snap = await firebase.firestore().doc(`/configuraciones/variables`).get();

        if(snap.exists){
       
            return snap.data();
        }
        return false;


    } catch (error) {
        return false;
    }
}


export async function getDeviceToken(uid) {
    if (uid) {
        try {
            let sna = await firebase.firestore().doc(`/usuarios/${uid}/configuraciones/token`).get();
            if (sna.exists) {

                let dataUserConfig = sna.data();
                if (dataUserConfig.device_token) {
                    return dataUserConfig.device_token;
                }
                return "";
            }
            return "";
        } catch (error) {
            return "";
        }
    }
    return "";
}

export async function getTipoServicios({ dataUser, currentContrato }) {
    let servicios = [];
    try {

        let tipoServiciosBody = SERVICES.TIPO_SERVICIO.body;
        tipoServiciosBody.IdUsuario = encryptValues(dataUser.IdUsuario);
        tipoServiciosBody.Contrato = encryptValues(currentContrato.Contrato);

        let resultTipoServicio = await makeCall(SERVICES.TIPO_SERVICIO, tipoServiciosBody);

        if (resultTipoServicio && resultTipoServicio.response && resultTipoServicio.response.Exito === 200) {
            let ttser = resultTipoServicio.response['TTSERVINVER']['TT-SERVINVER'];

            let filtro = ttser.filter((v, i) => {
                if (v.Contrato === currentContrato.Contrato) {
                    return v;
                }
            });

            if (ttser.length) {
                //va por los datos de firebase;
                let docsServicios = await firebase.firestore().collection('configuraciones/inversion/servicios').get();
                if (docsServicios.docs.length) {
                    for (let docS of docsServicios.docs) {
                        let dataDocs = docS.data();
                        dataDocs.NoEsUsuario = true;
                        if (dataDocs.habilitado) {
                            for (let f of filtro) {
                                if (dataDocs.id === f.Servicio) {
                                    dataDocs.NoEsUsuario = false;
                                }
                            }
                            servicios.push(dataDocs);
                        }
                    }
                }
            }
        }

        return servicios;

    } catch (error) {
        return servicios;
    }
}


export function deleteSesion(id_usuario, props, action) {

    try {
        firebase.auth().signOut().then(() => {

            if (window.suscribes) {
                for (let su of window.suscribes) {
                    su();
                }
            }

            if (window.unsubscribe) {
                window.unsubscribe();
            }
        }).catch((er) => {

        });
    } catch (error) {

    } finally {

        let instance = M.Sidenav.getInstance(document.querySelectorAll('.sidenavUser')[0]);
        if (instance) {
            instance.close();
            instance.destroy();
        }

        let instanceMenu = M.Sidenav.getInstance(document.querySelectorAll('.sidenav')[0]);

        if (instanceMenu) {
            instanceMenu.close();
            instanceMenu.destroy();
        }

        let modals = document.querySelectorAll('.modal');

        if (modals && modals.length) {
            for (let modal of modals) {
                let instance = M.Modal.getInstance(modal);
                if (instance) {
                    instance.close();
                }
            }
        }


        let bodyCerrarSesion = SERVICES.TERMINA_SESION.body;
        bodyCerrarSesion.idUsuario = encryptValues(id_usuario.toLowerCase());

        makeCall(SERVICES.TERMINA_SESION, bodyCerrarSesion).then(() => {
            localStorage.removeItem('dataUser');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('authorization');
            localStorage.removeItem('idToken');

            if (typeof props.setDataUser === "function") {
                props.setDataUser(false);
            }

            if (typeof props.setContratos === "function") {
                props.setContratos(false);
            }

            if (typeof props.setCurrentContrato === "function") {
                props.setCurrentContrato(false);
            }

            if (typeof props.setChequera === "function") {
                props.setChequera(false);
            }

            if(action){
                action();
            }

        });

        if (window.unsubscribe) {
            window.unsubscribe();
        }

    }

    try {
       
        let instanceMenuNo = M.Sidenav.getInstance(document.querySelectorAll('.sidenavNoSesion')[0]);

        if (instanceMenuNo) {
            instanceMenuNo.close();
            instanceMenuNo.destroy();
        }  

    } catch (error) {
        
    }
}

export function addSuscribe(sub) {
    if (window.suscribes) {
        window.suscribes.push(sub);
    } else {
        window.suscribes = [sub];
    }
}

export async function getRendimientos({ dataUser, currentContrato }) {
    try {

        let bodyRendimientos = SERVICES.RENDIMIENTOS.body;
        bodyRendimientos.IdUsuario = encryptValues(dataUser.IdUsuario);
        bodyRendimientos.Contrato = encryptValues(currentContrato ? currentContrato.Contrato : "");

        const resultRendimientos = await makeCall(SERVICES.RENDIMIENTOS, bodyRendimientos);

        if (resultRendimientos && resultRendimientos.response && resultRendimientos.response.Exito === 200) {
            return resultRendimientos.response;
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }

}

export function openTip(){
    let instance = M.Modal.getInstance(document.querySelectorAll('.modalRecarga')[0]);
    if(instance){
        instance.open();
    }
}


export function closeTip(){
    let instance = M.Modal.getInstance(document.querySelectorAll('.modalRecarga')[0]);
    if(instance){
        instance.close();
    }
}

export async function toReload({ dataUser, props }, contrato) {

    //obtiene la instancia del modal para recarga.
    let instance = M.Modal.getInstance(document.querySelectorAll('.modalRecarga')[0]);

    if (instance) {
        instance.open();
    } else {

        var instances = M.Modal.init(document.querySelectorAll('.modalRecarga'), {
            dismissible: false,
        });
        if (instances.length) {
            instance = instances[0];
            instance.open();
        }

    }

    try {

        if (!contrato) {
            let service = SERVICES.CONTRATOS;
            let body = service.body;
            body.IdUsuario = encryptValues(dataUser.IdUsuario);
            body.DeviceToken = dataUser.deviceToken;

            let resultContratos = await makeCall(service, body);

            props.setContratos(resultContratos.response);
            props.setCurrentContrato(resultContratos.response.TTCONTRATOS["TT-CONTRATOS"][0]);
            contrato = resultContratos.response.TTCONTRATOS["TT-CONTRATOS"][0];
        }

        props.setDataUser(dataUser);
        let serviceChequera = SERVICES.CHEQUERA;
        let bodyChequera = serviceChequera.body;
        bodyChequera.IdUsuario = encryptValues(dataUser.IdUsuario);
        bodyChequera.DeviceToken = dataUser.deviceToken;
   
        let chequera = await makeCall(serviceChequera, bodyChequera);
    
        props.setChequera(chequera.response.TTCUENTAS['TT-CUENTAS']);


        let saldoTotal = await getSaldoLiquido({
            dataUser,
            currentContrato: contrato,
            TipoSaldo: "P"
        });


        if (saldoTotal) {
            props.setSaldoTotal(saldoTotal.totalSaldo);
            props.setSaldoLiquido(saldoTotal.saldoLiquido);
        } else {
            props.setSaldoTotal(0);
        }

        const rendimientos = await getRendimientos({
            dataUser,
            currentContrato: contrato
        });

        props.setRendimientos(rendimientos);


        const servicios = await getTipoServicios(
            {
                dataUser,
                currentContrato: contrato
            }
        );

        props.setTiposServicios(servicios);

        let bodyPosicion = SERVICES.POSICION_CARTERA.body;
        bodyPosicion.IdUsuario = encryptValues(dataUser.IdUsuario);
        bodyPosicion.Contrato = encryptValues(contrato.Contrato);


        let resultCartera = await makeCall(SERVICES.POSICION_CARTERA, bodyPosicion);

        if (resultCartera && resultCartera.response) {
            props.setPosicionCartera(resultCartera.response);
        }

        instance.close();

    } catch (error) {
        instance.close();
    }

}


export function getComision(fondo, monto) {

    if (fondo.Comision) {
        return parseFloat(monto / 100 * fondo.Comision);
    }

    return 0;
}

export function getIva(fondo, monto) {
    if (fondo.IVAComision) {
        return roundDouble(parseFloat(monto / 100 * fondo.IVAComision), 2);
    }

    return 0;
}

export function roundDouble(value, places) {
    let mod = Math.pow(10.0, places);
    return (Math.round(value * mod) / mod);
}

export async function getDownloadUrl(path){
    try {
        
      const urlTapiz =  await  firebase.storage().ref(path).getDownloadURL();
      return urlTapiz;    

    } catch (error) {
        return false;
    }
}

export async function shareDetail(id) {
    const input = document.getElementById(id);
    const details = document.getElementsByClassName('detailCard');
    const cabeceraComprobante = document.getElementById('cabeceraComprobante');
    const footerCardDetail = document.getElementById('footerCardDetail');

    // debugger;

    if (details.length) {
        // var doc = new jsPDF();
        const doc = new jsPDF('p', 'px', 'a4');
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        for (let i = 0; i < details.length; i++) {
            let d = details[i];
            let canvas = await html2canvas(d, {
                scrollY: -window.scrollY,
                scrollX: -window.scrollX,
                scale: 1,
                y: -11,
                x: -100,
                width: d.clientWidth + 500,
                height: d.clientHeight + 100
            });

            var margin = 2;
            var position = 10;

            if (i === 0) {
                let canvasCabecera = await html2canvas(cabeceraComprobante, {
                    scrollY: -window.scrollY,
                    scrollX: -window.scrollX,
                    scale: 1,
                    y: -11,
                    x: -100,
                    width: cabeceraComprobante.clientWidth + 500,
                    height: cabeceraComprobante.clientHeight + 100
                });



                const canvasCabeceraDataUrl = canvasCabecera.toDataURL('image/png', 1.0)
                //  doc.addImage(canvasCabeceraDataUrl, 'PNG', 2, 0);

                const { marginX, canvasWidth, canvasHeight } = getCanvasSizes(canvasCabecera, pageWidth, pageHeight);
                doc.addImage(canvasCabeceraDataUrl, 'PNG', marginX, 0, canvasWidth + (pageWidth - (75 * pageWidth / 100)), canvasHeight + (15 * canvasHeight / 100));

                position = canvasHeight + 5;
            }

            const contentDataURL = canvas.toDataURL('image/png')


            var imgWidth = 290 - 2 * margin;
            var imgHeight = canvas.height * imgWidth / canvas.width;

            if (i > 0) {
                doc.addPage();
            }

            // doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
            const { marginX, canvasWidth, canvasHeight } = getCanvasSizes(canvas, pageWidth, pageHeight);
            doc.addImage(contentDataURL, 'PNG', marginX, position, canvasWidth + (pageWidth - (75 * pageWidth / 100)), canvasHeight + (15 * canvasHeight / 100));
            //doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
            doc.setFontSize(9);
            if (i === details.length - 1) {
                let footerCanvas = await html2canvas(footerCardDetail, {
                    scrollY: -window.scrollY,
                    scrollX: -window.scrollX,
                    scale: 1,
                    y: -11,
                    x: -100,
                    width: footerCardDetail.clientWidth + 500,
                    height: footerCardDetail.clientHeight + 100
                });
                const footerCanvasDataUrl = footerCanvas.toDataURL('image/png')

                let my = canvasHeight;

                if (details.length === 1) {
                    my += 40;
                }

                const rsFC = getCanvasSizes(footerCanvas, pageWidth, pageHeight);

                doc.addImage(footerCanvasDataUrl, 'PNG', marginX, my, rsFC.canvasWidth + (pageWidth - (75 * pageWidth / 100)), rsFC.canvasHeight + 10);
                // doc.text('Apreciable cliente recuerde que la única información oficial al respecto de su saldo, es la que usted recibe en su estado de cuenta.',pageWidth - 83, canvasHeight,null,null,"center");
            }
            doc.setFontSize(9);
            doc.text('Página ' + String(i + 1) + ' de ' + String(details.length), pageWidth - 83, pageHeight - 30, null, null, "right");
        }



        doc.save('Comprobante.pdf');
    }

    // html2canvas(input, {
    //     scrollY: -window.scrollY,
    //     scrollX: -window.scrollX,
    //     scale: 1,
    //     y: -11,
    //     x: -80,
    //     width: input.clientWidth + 500,
    //     height: input.clientHeight + 100
    // })
    //     .then((canvas) => {

    //         const contentDataURL = canvas.toDataURL('image/png')
    //         var margin = 2;
    //         var imgWidth = 290 - 2 * margin;
    //         var pageHeight = 295;
    //         var imgHeight = canvas.height * imgWidth / canvas.width;
    //         var heightLeft = imgHeight;

    //         var doc = new jsPDF('p', 'mm');
    //         var position = 0;

    //         doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);

    //         heightLeft -= pageHeight;

    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             doc.addPage();
    //             doc.addImage(contentDataURL, 'PNG', margin, position, imgWidth, imgHeight);
    //             heightLeft -= pageHeight;
    //         }
    //         doc.save('Comprobante.pdf');

    //     })
    //     ;

}

function getCanvasSizes(canvas, pageWidth, pageHeight) {
    // debugger;
    const widthRatio = pageWidth / canvas.width;
    const heightRatio = pageHeight / canvas.height;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = canvas.width * ratio;
    const canvasHeight = canvas.height * ratio;

    const marginX = (5 * pageWidth / 100);
    const marginY = (pageHeight - canvasHeight) / 2;

    return {
        canvasWidth, canvasHeight, marginX, marginY
    }
}

export function getInfo(obj){

    const keys = Object.keys(obj);

   for(let k of keys){
    if(obj[k].trim() != ""){
        // console.log("==========================")
        // console.log("PROPIEDAD ", k)
        // console.log("VALOR " , obj[k]);
        // console.log("__________________________")
        // console.log("VALOR DESENCRIPTADO");

        try {
            // console.log(decryptValue(obj[k]));
        } catch (error) {
            // console.log("No logro desencriptar " , k)
        }
        // console.log("==========================")
    }
   }
}   